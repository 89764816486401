import './style.css';
import { Link } from "react-scroll";

function Button({ buttonClass, buttonLink, buttonContent }) {
    return(
        <div className={buttonClass}>
            <Link to={buttonLink} smooth={true} duration={300} className='button-link'>{buttonContent}</Link>
        </div>
    )
};

export default Button;