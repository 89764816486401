import './style.css';
import Ellipse1 from '../../assets/img/Ellipse1.png';
import Ellipse2 from '../../assets/img/Ellipse2.png';
import Ellipse3 from '../../assets/img/Ellipse3.png';

function Background() {
    return(
        <section id='background'>
            <img src={Ellipse1} alt='ellipse1' className='ellipse1'/>
            <img src={Ellipse2} alt='ellipse1' className='ellipse2'/>
            <img src={Ellipse3} alt='ellipse1' className='ellipse3'/>
        </section>
    )
};

export default Background;