import './style.css';
import Logo from '../../assets/img/logo.png';

function Contact() {
    return(
        <section id='contact'>
            <div className='box-contact'>
                <h1><img src={Logo} alt='logo' className='logo-contact' /></h1>
                <h2>Localisation</h2>
                <div className='localisation'>
                    <p><strong>Green Azur</strong><br />41 boulevard Voltaire<br />75011 Paris<br />France</p>
                    <p><strong>Green Azur</strong><br />19 avenue Auguste Renoir<br />06800 Cagnes-sur-Mer<br />France</p>
                </div>
                <h2>Contact</h2>
                <div className='contact'>
                    <p><strong>Téléphone : </strong>04 89 03 68 65</p>
                    <p><strong>Mail : </strong>contact@greenazur.fr</p>
                </div>
            </div>
        </section>
    )
};

export default Contact;