import './style.css';
import Home from '../home';
import About from '../about';
import Contact from '../contact';
import Legal from '../legal';

function Landing() {
    return(
        <section id='landing'>
            <Home />
            <About />
            <Contact />
            <Legal />
        </section>
    )
};

export default Landing;