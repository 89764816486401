import './style.css';
import Logo from '../../assets/img/logo.png';
import Mobile from '../../assets/img/mobile.png';

function About() {
    return(
        <section id='about'>
            <div className='about-content'>
                <div className='about-content-left'>
                    <img src={Logo} alt='logo' className='logo-about' />
                    <p>
                        Depuis notre fondation en 2017, notre engagement indéfectible est de vous offrir une expérience exceptionnelle dans le monde des produits technologiques, en répondant de manière dynamique à vos besoins en constante évolution.
                        <br /><br />
                        Au sein de notre établissement, vous découvrirez une gamme diversifiée de matériel informatique et électronique, rigoureusement sélectionnée pour sa qualité supérieure et sa performance inégalée.
                        <br /><br />
                        Notre équipe de passionnés se distingue par sa connaissance approfondie des produits que nous proposons. Elle se tient à votre disposition pour vous guider avec expertise et bienveillance, vous aidant ainsi à identifier le produit qui non seulement répondra à vos besoins présents, mais qui évoluera harmonieusement avec vos aspirations technologiques futures.
                    </p>
                </div>
                <div className='about-content-right'>
                    <img src={Mobile} alt='mobile' className='mobile' />
                </div>
            </div>
        </section>
    )
};

export default About;