import './style.css';
import Logo from '../../assets/img/logo.png';
import Nav from '../nav';

function Header() {
    return(
        <header>
            <img src={Logo} alt='Green Azur' className='logo-top' />
            <Nav />
        </header>
    )
};

export default Header;