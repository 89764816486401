import './style.css';
import Header from '../../components/header';
import Background from '../../components/background';
import Macbook from '../../assets/img/macbook.png';
import Button from '../../components/button';

function Home() {
    return(
        <section id='home'>
            <Background />
            <Header />
            <div className='home-content'>
                <div className='home-content-left'>
                    <h1>Bienvenue chez Green Azur</h1>
                    <p>Spécialisée en informatique et électronique, notre équipe d'experts passionnés est là pour vous aider à choisir le produit qui correspond le mieux à vos besoins.</p>
                    <div className='home-button'>
                        <Button
                            buttonClass={'btn btn-transparent'}
                            buttonContent={'En savoir plus'}
                            buttonLink={'about'}
                        />
                        <Button 
                            buttonClass={'btn btn-color'}
                            buttonContent={'Nous contacter'}
                            buttonLink={'contact'}
                        />
                    </div>
                </div>
                <img src={Macbook} alt='ordinateur' className='computer' />
            </div>
        </section>
    )
};

export default Home;