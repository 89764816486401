import './style.css';

function Legal() {
    return(
        <section id='legal'>
            <div className='box-legal'>
                <h2>Mentions légales</h2>
                <div className='legal'>
                    <p className='p-legal'><strong>Nom de l'entreprise :</strong> Green Azur<br /><strong>Forme juridique : </strong>SASU<br /><strong>Adresse du siège social :</strong> 41 boulevard Voltaire 75011 Paris<br /><strong>Numéro SIRET :</strong> 83171972900020<br /><strong>Contact :</strong> contact@greenazur.fr</p>
                    <h3>Propriété intellectuelle :</h3>
                    <p>L'ensemble du contenu présent sur ce site web, y compris, mais sans s'y limiter, les textes, les images, les graphiques, les logos et les vidéos, est la propriété exclusive de <strong>Green Azur</strong> et est protégé par les lois sur la propriété intellectuelle en vigueur. Toute reproduction, distribution, modification ou utilisation non autorisée du contenu est strictement interdite.</p>
                    <h3>Responsabilité :</h3>
                    <p><strong>Green Azur</strong> s'efforce de fournir des informations précises et à jour sur ce site web, mais ne peut garantir l'exactitude, l'exhaustivité ou la pertinence des informations fournies. En conséquence, <strong>Green Azur</strong> décline toute responsabilité pour les éventuelles erreurs ou omissions dans le contenu du site.</p>
                    <h3>Liens externes :</h3>
                    <p>Ce site web peut contenir des liens vers des sites web externes qui sont fournis uniquement pour votre commodité. <strong>Green Azur</strong> n'exerce aucun contrôle sur le contenu de ces sites externes et décline toute responsabilité quant à leur contenu.</p>
                    <h3>Protection des données personnelles :</h3>
                    <p>Les informations personnelles collectées sur ce site web ne seront utilisées que dans le cadre de votre demande d'information ou de votre demande de contact. Elles ne seront en aucun cas vendues, louées ou divulguées à des tiers sans votre consentement préalable. <strong>Green Azur</strong> met en place des mesures de sécurité appropriées pour protéger vos données personnelles.</p>
                </div>
            </div>
        </section>
    )
};

export default Legal;