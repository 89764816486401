import './style.css';
import { Link } from "react-scroll";
import React, { useState } from 'react';

function Nav() {
    const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = () => {
    setIsOpen(false);
  };

    return(
        <nav>
            <input id="menu-toggle" type="checkbox" checked={isOpen} onChange={() => setIsOpen(!isOpen)} />
            <label className="menu-button-container" htmlFor="menu-toggle">
                <div className="menu-button"></div>
            </label>
            <ul className={`menu ${isOpen ? 'open' : ''}`}>
                <li className='nav-item'><Link to='home' smooth={true} duration={300} onClick={handleItemClick}>Accueil</Link></li>
                <li className='nav-item'><Link to='about' smooth={true} duration={300} onClick={handleItemClick}>Qui sommes-nous ?</Link></li>
                <li className='nav-item'><Link to='contact' smooth={true} duration={300} onClick={handleItemClick}>Contact</Link></li>
                <li className='nav-item'><Link to='legal' smooth={true} duration={300} onClick={handleItemClick}>Mentions légales</Link></li>
            </ul>
        </nav>
    )
};

export default Nav;